import React, { useEffect, useRef } from "react"
import { AspectRatio, Box, Button, Container, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import Link from "@app/components/Link"

type Props = GatsbyTypes.SanitySectionImageText & AnalyticProps

const ImageText: React.FC<Props> = ({
  menuTitle,
  image,
  backgroundColour,
  title,
  description,
  link,
  innerRef,
  handleTrackingClick,
  tag,
}) => {
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()
  const { saveProductItems } = useAppContext()

  const customImage = getGatsbyImage(image)
  const customLink = urlResolver(link)
  const bgColor = `background.${backgroundColour?.toLowerCase()}`
  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  return customImage && title && description ? (
    <Box as="section" ref={sectionRef}>
      <Container ref={innerRef} maxW="container.4xl" py={{ md: "9", lg: "18" }} px={{ base: "0", md: "10", lg: "20" }}>
        <Flex flexDir={{ base: "column", md: "row" }}>
          <AspectRatio
            ratio={367 / 376}
            w={{ md: "45.31%" }}
            borderTopLeftRadius={{ md: "xl" }}
            borderBottomLeftRadius={{ md: "xl" }}
            borderRadius={{ lg: "xl" }}
            overflow={{ md: "hidden" }}
            boxShadow={{ lg: "twinShadow" }}
          >
            <Image {...customImage} />
          </AspectRatio>

          <Flex flexDir="column" justifyContent="center" w={{ md: "54.68%" }}>
            <VStack
              alignItems="flex-start"
              justifyContent={{ xl: "center" }}
              spacing={{ base: "3", lg: "4" }}
              py={{ base: "8", lg: "10", xl: "20" }}
              px={{ base: "4", md: "10", xl: "20" }}
              w="full"
              minH={{ xl: "125" }}
              bg={bgColor}
              color="background.white"
              borderTopRightRadius={{ md: "xl" }}
              borderBottomRightRadius={{ md: "xl" }}
            >
              <Heading as={tag} size="h2">
                {title}
              </Heading>

              <Text size="paragraphLarge" whiteSpace="pre-line">
                {description}
              </Text>

              {customLink && (
                <Box w="full" textAlign="left">
                  <Button
                    as={Link}
                    to={customLink.url}
                    isExternal={customLink.external}
                    onClick={handleTrackingClick}
                    variant="outlineInverted"
                    minW="40"
                  >
                    {customLink.title}
                  </Button>
                </Box>
              )}
            </VStack>
          </Flex>
        </Flex>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(ImageText))
